<template>
    <Carousel
        :items-to-show="getItemsToShow"
        snap-align="start"
        class="apr-carousel"
        :mouse-drag="sliderItems.length > getItemsToShow"
        :class="{ 'is-less-items': sliderItems.length <= getItemsToShow }"
    >
        <slide v-for="(item, index) in sliderItems" :key="index">
            <slot :item="item" />
        </slide>

        <template #addons>
            <template v-if="getItemsToShow && sliderItems.length > getItemsToShow">
                <navigation>
                    <template #prev>
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.999999 10L4.48046 6.66667L6.93031 4.32039L11.4414 4.85949e-07L14.9219 3.33333L10.4277 7.63754L17 7.63754L17 12.3463L10.4277 12.3463L14.9219 16.6667L11.4414 20L6.93031 15.6796L4.48047 13.3333L0.999999 10Z"
                                fill="#B4E076"
                            />
                        </svg>
                    </template>
                    <template #next>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                            <path
                                d="M18 10L14.5195 13.3333L12.0697 15.6796L7.55861 20L4.07814 16.6667L8.57233 12.3625H2L2 7.65372L8.57233 7.65372L4.07814 3.33333L7.55861 0L12.0697 4.32039L14.5195 6.66667L18 10Z"
                                fill="#B4E076"
                            />
                        </svg>
                    </template>
                </navigation>
                <Pagination
            /></template>
        </template>
    </Carousel>
</template>

<script setup lang="ts">
const { sliderItems, sliderOptions } = defineProps<{
    sliderItems: any;
    sliderOptions: any;
}>();

// SLIDER
import 'vue3-carousel/dist/carousel.css';
// @ts-ignore
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
const innerWidth = ref(764); // Below 768 for LCP and CLS

function onResize() {
    innerWidth.value = window.innerWidth;
}

if (import.meta.client) {
    window.addEventListener('resize', onResize);
    onResize();
}
onUnmounted(() => window.removeEventListener('resize', onResize));

const getItemsToShow = computed(() => {
    if (innerWidth.value >= 1280) {
        return sliderOptions?.slidesCount?.xl ?? 8;
    } else if (innerWidth.value < 1280 && innerWidth.value >= 1024) {
        return sliderOptions?.slidesCount?.lg ?? 6;
    } else if (innerWidth.value < 1024 && innerWidth.value >= 768) {
        return sliderOptions?.slidesCount?.md ?? 4;
    } else {
        return sliderOptions?.slidesCount?.sm ?? 3;
    }
});
</script>

<style lang="postcss">
.apr-carousel {
    &.carousel {
        .carousel__slide {
            @apply px-3;
            & > * {
                @apply w-full;
            }
        }
        .carousel__next,
        .carousel__prev {
            @apply h-10 w-10 text-lg rounded-full bg-white transition-all mx-0 top-[46%] sm:top-[200px];
            @apply xl:w-11 xl:h-11;

            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
        }
        .carousel__next {
            @apply right-0 sm:-right-2;
        }
        .carousel__prev {
            @apply left-0 sm:-left-2;
        }
        .carousel__next--disabled,
        .carousel__prev--disabled {
            @apply opacity-0 pointer-events-none;
        }
        .carousel__pagination {
            @apply flex mt-6;
        }
        .carousel__pagination-button {
            &::after {
                @apply w-2 h-2 rounded-full bg-brand-secondary-500 opacity-40;
            }
        }
        .carousel__pagination-button--active,
        .carousel__pagination-button:hover {
            &::after {
                @apply opacity-100 scale-125;
            }
        }
    }
}
</style>
